import BaseMethods from '../BaseMethods';
import {getData} from "@/api/v2/utils/DataHandler";

export default new (class extends BaseMethods {
    endpointRoute = '/api/v2/projects/:projectId/tasks';

    async index(projectId, params) {
        return this.setRouteBindings({projectId}).asJson().get(this.endpointRoute, {params}).then(getData);
    }

    async deleteUploadedContract(id) {
        const route = `/api/v2/tasks/${id}/delete-uploaded-contract`;

        return this.asJson().get(route).then(getData);
    }

    async deleteUploadedRequestDocument(id) {
        const route = `/api/v2/tasks/${id}/delete-uploaded-request-document`;

        return this.asJson().get(route).then(getData);
    }
})();
